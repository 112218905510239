var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "data-v" }, [
    _c("iframe", {
      attrs: {
        src: _vm.iframeSrc,
        allowfullscreen: "true",
        webkitallowfullscreen: "true",
        mozallowfullscreen: "true",
        frameborder: "0"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="data-v">
    <iframe
      :src="iframeSrc"
      allowfullscreen="true"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      frameborder="0"
    >
    </iframe>
  </div>
</template>
<script>
export default {
  data() {
    return {
      iframeSrc: `https://datav.aliyun.com/share/page/d68d7413db93ddf221c6d4b4fe47042c?token=${sessionStorage.getItem(
        "token"
      ) || localStorage.getItem("token")}`
    };
  },
  mounted() {},
  methods: {}
};
</script>
<style lang="scss" scoped>
.data-v {
  height: 100%;
  position: relative;
  iframe {
    width: 100%;
    height: 100%;
    display: block;
  }
}
</style>
